import { selectURI } from '@apollo/client';
import uniqueId from 'lodash/uniqueId';

var getUniqueBatchGroup = function getUniqueBatchGroup() {
  return uniqueId('batchGroup_');
};

var getBatchGroup = function getBatchGroup(_ref) {
  var doNotBatch = _ref.doNotBatch,
      batchGroup = _ref.batchGroup;
  if (doNotBatch === true) return getUniqueBatchGroup();
  if (batchGroup) return batchGroup;
  return;
}; // See the default function:
// https://github.com/apollographql/apollo-client/blob/main/src/link/batch-http/batchHttpLink.ts#L192-L206


export var createBatchKeyBuilder = function createBatchKeyBuilder(apiUri) {
  return function (operation) {
    var context = operation.getContext();
    var contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers,
      batchGroup: getBatchGroup(context)
    }; //may throw error if config not serializable

    return selectURI(operation, apiUri) + JSON.stringify(contextConfig);
  };
};