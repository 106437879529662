import { ICodeEnum } from '@r-client/data/graphql-types';

var isValidationError = function isValidationError(gqlError) {
  return !!(gqlError.__typename && gqlError.__typename === 'ValidationError');
};

export var toValidationErrors = function toValidationErrors() {
  var gqlErrors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return gqlErrors.filter(isValidationError);
};

var isUserInputError = function isUserInputError(error) {
  return error.extensions && error.extensions.code === ICodeEnum.BadUserInput;
};

export var getUserInputErrors = function getUserInputErrors(errors) {
  return errors.reduce(function (record, error) {
    if (isUserInputError(error)) {
      var _error$extensions;

      var field = (_error$extensions = error.extensions) === null || _error$extensions === void 0 ? void 0 : _error$extensions.field;

      if (field) {
        record[field] = error.message;
      }
    }

    return record;
  }, {});
};