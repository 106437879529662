import { useMemo } from 'react';
import isObject from 'lodash/isObject';
import { createGqlClient } from './data-client';
import { GQL_STATE_PROP_NAME } from './with-gql-client-state';
export function useGqlManager(pageProps, clientConfig, reporting) {
  var state = isObject(pageProps) ? pageProps[GQL_STATE_PROP_NAME] : undefined;
  var client = useMemo(function () {
    return createGqlClient({
      initialState: state,
      config: clientConfig,
      reporting: reporting
    });
  }, [state, clientConfig, reporting]);
  return client;
}