import { getWindow } from './global';

var getCookies = function getCookies() {
  var _getWindow;

  return ((_getWindow = getWindow()) === null || _getWindow === void 0 ? void 0 : _getWindow.document.cookie) || '';
};
/**
 *
 * @param key
 * @returns string | undefined
 * Returns the value of a cookie by name.
 */


export var getCookie = function getCookie(key) {
  var _getCookies$split$fin;

  return (_getCookies$split$fin = getCookies().split('; ').find(function (row) {
    return row.startsWith("".concat(key, "="));
  })) === null || _getCookies$split$fin === void 0 ? void 0 : _getCookies$split$fin.split('=')[1];
};

/**
 *
 * @param {name, value, options}: SetCookie
 * @returns void
 * Sets a cookie with the given name, value and options.
 */
export var setCookie = function setCookie(_ref) {
  var name = _ref.name,
      value = _ref.value,
      options = _ref.options;
  var window = getWindow();
  if (!window) return;

  var _ref2 = options || {},
      _ref2$maxAge = _ref2.maxAge,
      maxAge = _ref2$maxAge === void 0 ? 604800 : _ref2$maxAge,
      _ref2$sameSite = _ref2.sameSite,
      sameSite = _ref2$sameSite === void 0 ? 'Strict' : _ref2$sameSite,
      _ref2$secure = _ref2.secure,
      secure = _ref2$secure === void 0 ? true : _ref2$secure,
      _ref2$domain = _ref2.domain,
      domain = _ref2$domain === void 0 ? process.env.NEXT_PUBLIC_TLD : _ref2$domain;

  var cookie = "".concat(name, "=").concat(value, "; max-age=").concat(maxAge, "; SameSite=").concat(sameSite, "; Path=/; Secure=").concat(secure, "; Domain=").concat(domain);
  window.document.cookie = cookie;
};
/**
 *
 * @param name
 * @returns void
 * Deletes a cookie by name by setting its expiration date to the past.
 */

export var deleteCookie = function deleteCookie(name) {
  var window = getWindow();
  if (!window) return;
  window.document.cookie = "".concat(name, "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;");
};