import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// eslint-disable-next-line @nx/enforce-module-boundaries
import { theme as defaultTheme } from '@chakra-ui/theme';
export var Select = {
  variants: {
    'republic-outline': function republicOutline(props) {
      var _defaultTheme$compone, _defaultTheme$compone2;

      return _objectSpread(_objectSpread({}, (_defaultTheme$compone = defaultTheme.components.Select.variants) === null || _defaultTheme$compone === void 0 ? void 0 : _defaultTheme$compone.outline(props)), {}, {
        field: _objectSpread(_objectSpread({}, (_defaultTheme$compone2 = defaultTheme.components.Select.variants) === null || _defaultTheme$compone2 === void 0 ? void 0 : _defaultTheme$compone2.outline(props).field), {}, {
          borderColor: 'brand.grey.e0',
          _hover: {
            boxShadow: '0 4px 20px rgb(0 0 0 / 10%)'
          },
          _active: {
            borderColor: 'brand.primary',
            boxShadow: '0 0 0 1px #0049ff'
          },
          _focusVisible: {
            borderColor: 'brand.primary',
            boxShadow: '0 0 0 1px #0049ff'
          },
          background: 'brand.white'
        }),
        icon: {
          '> svg': {
            fill: 'brand.grey.95'
          }
        }
      });
    },
    'republic-outline-lite': function republicOutlineLite(props) {
      var _defaultTheme$compone3, _defaultTheme$compone4;

      return _objectSpread(_objectSpread({}, (_defaultTheme$compone3 = defaultTheme.components.Select.variants) === null || _defaultTheme$compone3 === void 0 ? void 0 : _defaultTheme$compone3.outline(props)), {}, {
        field: _objectSpread(_objectSpread({}, (_defaultTheme$compone4 = defaultTheme.components.Select.variants) === null || _defaultTheme$compone4 === void 0 ? void 0 : _defaultTheme$compone4.outline(props).field), {}, {
          pl: '10px',
          pr: '12px',
          height: '42px',
          bg: 'white',
          borderColor: 'grey.100',
          borderRadius: '4px',
          outline: 'none',
          fontSize: 'md',
          lineHeight: 'md',
          fontWeight: 400,
          letterSpacing: 'less-tight',
          option: {
            color: 'black'
          },
          '> option[value=""]': {
            display: 'none'
          },
          _active: {
            borderColor: 'brand.blue.c6'
          },
          _hover: {
            borderColor: 'grey.200',
            boxShadow: 'none'
          },
          _focusVisible: {
            borderColor: 'blue.500',
            boxShadow: 'none'
          },
          _disabled: {
            bg: 'grey.50',
            borderColor: 'grey.200'
          },
          _valid: {
            borderColor: 'green.500'
          },
          _invalid: {
            borderColor: 'red.500'
          }
        }),
        icon: {
          w: '18px',
          h: '18px',
          '> svg': {
            w: '18px',
            h: '18px',
            color: 'grey.400'
          }
        }
      });
    }
  }
};