import { onError } from '@apollo/client/link/error';
import debug from 'debug';
import get from 'lodash/get';
var log = debug('data:client:link:error');
export function createErrorLink(reporting) {
  var errorLink = onError(function (_ref) {
    var graphQLErrors = _ref.graphQLErrors,
        operation = _ref.operation;
    var lastError = {
      message: '',
      operationName: ''
    };

    if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.forEach(function (_ref2) {
        var message = _ref2.message;
        log(operation, message);

        if ((lastError.operationName !== get(operation, 'operationName') || lastError.message !== message) && reporting && typeof reporting.error === 'function') {
          log('Error reporting to rollbar!');
          reporting.error("GraphQL                    Operation ".concat(get(operation, 'operationName'), " failed with ").concat(message), {
            variables: get(operation, 'variables')
          });
          lastError.message = message;
          lastError.operationName = get(operation, 'operationName');
        }
      });
    }
  });
  return errorLink;
}