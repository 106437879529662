import { useMemo } from 'react';
import axios from 'axios';
export var createSeedrsClient = function createSeedrsClient(config) {
  if (!config.baseURL) {
    throw new Error('Seedrs API base URL not provided');
  }

  return axios.create({
    baseURL: config.baseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
};
export var useSeedrsClientManager = function useSeedrsClientManager(config) {
  var client = useMemo(function () {
    return createSeedrsClient(config);
  }, [config]);
  return client;
};