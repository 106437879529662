import _toConsumableArray from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';
import { isMSWEnabled, MSW_POSSIBLE } from '@r-client/shared/data/flags/local';
import { createBatchKeyBuilder } from './config/batch-config';
import { CacheConfig } from './config/cache-config';
import { createAuthCookieLink } from './link/auth-cookie';
import { createAuthLocalStorageLink } from './link/auth-local-storage';
import { createInfraAuth } from './link/infra-header';

function createApolloClient(_ref) {
  var initialState = _ref.initialState,
      _ref$queryBatchingEna = _ref.queryBatchingEnabled,
      queryBatchingEnabled = _ref$queryBatchingEna === void 0 ? true : _ref$queryBatchingEna,
      apiAuthHeader = _ref.apiAuthHeader,
      apiUri = _ref.apiUri,
      clientName = _ref.clientName,
      clientVersion = _ref.clientVersion,
      reporting = _ref.reporting,
      authPersistence = _ref.authPersistence,
      defaultOptions = _ref.defaultOptions;
  var retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: 3
    },
    attempts: {
      max: 3,
      retryIf: function retryIf(error, _operation) {
        var doNotTryCodes = [400];
        return !!error && !doNotTryCodes.includes(error.statusCode);
      }
    }
  });
  var clientNameLink = setContext(function (_, _ref2) {
    var headers = _ref2.headers;
    return {
      headers: _objectSpread(_objectSpread({}, headers), {}, {
        'apollographql-client-name': clientName,
        'apollographql-client-version': clientVersion
      })
    };
  });

  var authLink = function chooseAuthLink(variant) {
    if ((variant === null || variant === void 0 ? void 0 : variant.type) === 'localStorage') return createAuthLocalStorageLink({
      apiUri: apiUri,
      apiAuthHeader: apiAuthHeader,
      reporting: reporting
    });

    if ((variant === null || variant === void 0 ? void 0 : variant.type) === 'cookie') {
      return createAuthCookieLink({
        apiUri: apiUri,
        apiAuthHeader: apiAuthHeader,
        reporting: reporting,
        authCookiePrefix: variant.cookiePrefix
      });
    }

    return undefined;
  }(authPersistence);

  var HttpLinkImpl = queryBatchingEnabled ? BatchHttpLink : HttpLink;
  var httpLink = new HttpLinkImpl(_objectSpread({
    uri: apiUri,
    batchKey: createBatchKeyBuilder(apiUri)
  }, (authPersistence === null || authPersistence === void 0 ? void 0 : authPersistence.type) === 'cookie' ? {
    credentials: 'include'
  } : {}));
  var cache = new InMemoryCache(CacheConfig).restore(initialState || {});
  var linkChain = [createInfraAuth(apiAuthHeader), clientNameLink];

  if (authLink) {
    linkChain.push(authLink);
  }

  return new ApolloClient({
    link: ApolloLink.from([].concat(linkChain, [retryLink, httpLink])),
    ssrMode: false,
    cache: cache,
    defaultOptions: defaultOptions
  });
} // MSW does not support it


var queryBatchingEnabled = !(MSW_POSSIBLE && isMSWEnabled());
var apolloClient;
export function createGqlClient(_ref3) {
  var _apolloClient2;

  var _ref3$initialState = _ref3.initialState,
      initialState = _ref3$initialState === void 0 ? undefined : _ref3$initialState,
      config = _ref3.config,
      reporting = _ref3.reporting;

  var _apolloClient = (_apolloClient2 = apolloClient) !== null && _apolloClient2 !== void 0 ? _apolloClient2 : createApolloClient(_objectSpread({
    queryBatchingEnabled: queryBatchingEnabled,
    reporting: reporting
  }, config)); // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here


  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    var existingCache = _apolloClient.extract(); // Merge the existing cache into data passed from getStaticProps/getServerSideProps


    var data = mergeWith(existingCache, initialState, // combine arrays using object equality (like in sets)
    function (destinationArray, sourceArray) {
      if (isArray(destinationArray) && isArray(sourceArray)) {
        return [].concat(_toConsumableArray(sourceArray), _toConsumableArray(destinationArray.filter(function (d) {
          return sourceArray.every(function (s) {
            return !isEqual(d, s);
          });
        })));
      }
    }); // Restore the cache with the merged data

    _apolloClient.cache.restore(data);
  } // For SSG and SSR always create a new Apollo Client


  if (false) return _apolloClient; // Create the Apollo Client once in the client

  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}