/* eslint-disable @nx/enforce-module-boundaries */
import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

var _createMultiStyleConf = createMultiStyleConfigHelpers(formAnatomy.keys),
    defineMultiStyleConfig = _createMultiStyleConf.defineMultiStyleConfig;

export var Form = defineMultiStyleConfig({
  baseStyle: {
    helperText: {
      fontSize: '12px',
      color: 'brand.grey.7c'
    }
  }
});