/* eslint-disable filename-rules/match */
import 'assets/styles/index.scss';
import '@r-client/republic/feature/user-dashboard/global.css';
import '@r-client/shared/ui/core/global.tooltip.css';
import '@r-client/republic/ui/chakra-ui-form/global.input-phone.scss';
import '@r-client/shared/ui/media/global.css';
import '@r-client/shared/ui/froala-editor/froala-editor.global.scss';

import React, { ComponentType } from 'react';

import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { BannerProvider } from '@r-client/republic/feature/banners';
import { Braze } from '@r-client/republic/feature/braze';
import { TransferProvider } from '@r-client/republic/feature/digital-assets-core';
import { ErrorBoundary } from '@r-client/republic/feature/errors';
import {
  AnalyticsProvider,
  InitAnalytics,
} from '@r-client/shared/data/analytics';
import {
  GqlClientProvider,
  isMobileWebView,
  SeedrsClientContext,
  useSeedrsClientManager,
} from '@r-client/shared/data/client';
import {
  createReportingConfig,
  ErrorReportingProvider,
  NavigationContextHandler,
  useReporting,
} from '@r-client/shared/data/error-reporting';
import { isWebVitalsEnabled } from '@r-client/shared/data/flags/local';
import { AuthProvider } from '@r-client/shared/feature/auth';
import { ConfigProvider } from '@r-client/shared/util/core';

import { getPublicConfig } from '../application.config';
import { useGqlManager } from '../gql-client';

if (isWebVitalsEnabled()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { initWebVitals } = require('@r-client/shared/data/analytics');
  initWebVitals();
}

type Page = NextPage & {
  layout?: ComponentType<React.PropsWithChildren<unknown>>;
};

type Props = AppProps & {
  Component: Page;
};

const publicConfig = getPublicConfig();

const _reportingConfig = createReportingConfig({
  accessToken: publicConfig.rollbarClientToken,
  codeVersion: publicConfig.applicationVersion,
  environment: publicConfig.applicationEnvironment,
  serverHost: publicConfig.graphqlEndpointUrl,
  kind: 'rollbar',
});

const osanoUserId = 'AzqSHBT6fzKGV18Hs';
const osanoConfigId = '9e780d5a-e8d0-4873-932a-02822fc4194d';

function CustomApp({ Component, pageProps }: Props) {
  const reporting = useReporting();
  const gqlClient = useGqlManager(pageProps, reporting);
  const seedrsClient = useSeedrsClientManager({
    baseURL: publicConfig.seedrsApiBaseUrl,
  });
  const isMobileApp = isMobileWebView();

  return (
    <GqlClientProvider client={gqlClient} reporting={reporting}>
      <SeedrsClientContext.Provider value={seedrsClient}>
        <NavigationContextHandler useRouter={useRouter} />
        <ErrorBoundary>
          <Head>
            <title>Welcome to Republic!</title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width, minimum-scale=1.0"
            />
          </Head>
          {!isMobileApp && (
            <Script
              src={`https://cmp.osano.com/${osanoUserId}/${osanoConfigId}/osano.js`}
              async
            />
          )}
          {!!publicConfig.segmentClientWriteKey && (
            <InitAnalytics
              config={{
                segmentWriteKey: publicConfig.segmentClientWriteKey,
              }}
            />
          )}
          <ConfigProvider value={publicConfig}>
            <AnalyticsProvider>
              <AuthProvider seedrsAuthEnabled>
                <BannerProvider>
                  <TransferProvider>
                    <Component {...pageProps} />
                  </TransferProvider>
                </BannerProvider>
                <Braze />
              </AuthProvider>
            </AnalyticsProvider>
          </ConfigProvider>
        </ErrorBoundary>
      </SeedrsClientContext.Provider>
    </GqlClientProvider>
  );
}

function AppWithErrorReporting(props: Props) {
  return (
    <ErrorReportingProvider config={_reportingConfig}>
      <CustomApp {...props} />
    </ErrorReportingProvider>
  );
}

export default AppWithErrorReporting;
