import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

/* eslint-disable @nx/enforce-module-boundaries */
import { cssVar } from '@chakra-ui/react';
var $arrowBg = cssVar('popper-arrow-bg');
export var Tooltip = {
  variants: {
    republic: _defineProperty({
      background: 'grey.900',
      color: 'white',
      px: '3',
      py: '2.5',
      borderRadius: 'md'
    }, $arrowBg.variable, '#222222')
  }
};