// This is a build time optimisation we require for development

/* eslint-disable no-process-env */
import { getLocalStorage } from '@r-client/shared/util/core'; // Variables should be computed without runtime. To ensure webpack can optimize it

export var MSW_POSSIBLE = process.env.STORYBOOK || false || false && true;
export var isMSWEnabled = function isMSWEnabled() {
  var _getLocalStorage;

  return !!process.env.STORYBOOK || ((_getLocalStorage = getLocalStorage()) === null || _getLocalStorage === void 0 ? void 0 : _getLocalStorage.getItem('enableMSW')) === 'true';
};
export var isWebVitalsEnabled = function isWebVitalsEnabled() {
  var _getLocalStorage2;

  if (false) {
    return true;
  }

  return false && true && ((_getLocalStorage2 = getLocalStorage()) === null || _getLocalStorage2 === void 0 ? void 0 : _getLocalStorage2.getItem('enableWebVitals')) === 'true';
};