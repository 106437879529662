import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _defineStyle, _defineStyle2;

import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';
var grey = defineStyle((_defineStyle = {
  opacity: 1
}, _defineProperty(_defineStyle, cssVar('skeleton-start-color').variable, 'colors.grey.50'), _defineProperty(_defineStyle, cssVar('skeleton-end-color').variable, 'colors.grey.50'), _defineStyle));
var grey100 = defineStyle((_defineStyle2 = {
  opacity: 1
}, _defineProperty(_defineStyle2, cssVar('skeleton-start-color').variable, 'colors.grey.100'), _defineProperty(_defineStyle2, cssVar('skeleton-end-color').variable, 'colors.grey.100'), _defineStyle2));
export var Skeleton = defineStyleConfig({
  variants: {
    'republic-grey': grey,
    'republic-grey.100': grey100
  }
});