import { relayStylePagination } from '@apollo/client/utilities';
export var CacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        events: relayStylePagination(['future', 'past', 'states', 'order']),
        journalArticles: relayStylePagination(['categories']),
        tags: relayStylePagination(),
        messages: relayStylePagination(['userSlug']),
        savedOfferingsForUser: relayStylePagination(['userSlug']),
        notifications: relayStylePagination(['scope', 'order']),
        opportunities: relayStylePagination(),
        profileUpdates: relayStylePagination(['scope']),
        offeringUpdates: relayStylePagination(['scope']),
        offerings: relayStylePagination(['scopeFilter'])
      }
    }
  }
};