export var E_SIGN_IN_ERROR_CODES;

(function (E_SIGN_IN_ERROR_CODES) {
  E_SIGN_IN_ERROR_CODES["BAD_USER_INPUT"] = "BAD_USER_INPUT";
  E_SIGN_IN_ERROR_CODES["DOWNSTREAM_SERVICE_ERROR"] = "DOWNSTREAM_SERVICE_ERROR";
  E_SIGN_IN_ERROR_CODES["TWO_FACTOR_EXPIRED"] = "TWO_FACTOR_EXPIRED";
})(E_SIGN_IN_ERROR_CODES || (E_SIGN_IN_ERROR_CODES = {}));

export var E_SIGN_OUT_ACTION;

(function (E_SIGN_OUT_ACTION) {
  E_SIGN_OUT_ACTION["USER"] = "USER";
})(E_SIGN_OUT_ACTION || (E_SIGN_OUT_ACTION = {}));