import { IFlipperEnum } from '@r-client/data/graphql-types';
import { useGetFeatureToggleQuery } from '@r-client/shared/data/flags/remote';
export var useBrazeFeatureFlag = function useBrazeFeatureFlag() {
  var _useGetFeatureToggleQ = useGetFeatureToggleQuery({
    variables: {
      feature: IFlipperEnum.WebSdkBraze
    }
  }),
      data = _useGetFeatureToggleQ.data,
      isLoading = _useGetFeatureToggleQ.isLoading;

  return !isLoading && (data === null || data === void 0 ? void 0 : data.flipper.enabled);
};