import { useEffect } from 'react';
import axios from 'axios';
import { useReporting } from '@r-client/shared/data/error-reporting';
import { typeCast } from '@r-client/shared/util/core';
var client = axios.create({
  baseURL: '/',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});
export var useBackendAnalyticsIntegration = function useBackendAnalyticsIntegration(hasUser) {
  var errorReporting = useReporting();
  useEffect(function () {
    var handleRouteChange = function handleRouteChange() {
      client.post("/r_client_session_post".concat(window.location.search))["catch"](function (err) {
        errorReporting.error('Failed to sync session with server', typeCast(err));
      });
    };

    if (hasUser) {
      handleRouteChange();
    }
  }, [errorReporting, hasUser]);
};