import { getCookie, getWindow } from '@r-client/shared/util/core';
var ONE_MINUTES_MILLISECONDS = 60000;
export function redirectAfterInvalidation(redirectTo, reporting) {
  var _getWindow;

  var location = (_getWindow = getWindow()) === null || _getWindow === void 0 ? void 0 : _getWindow.location;
  reporting === null || reporting === void 0 ? void 0 : reporting.debug('Invalidating token', {
    location: location === null || location === void 0 ? void 0 : location.href
  });
  location === null || location === void 0 ? void 0 : location.assign(redirectTo);
}

var prefixedName = function prefixedName(prefix, name) {
  return prefix ? "".concat(prefix, "-").concat(name) : name;
};

export var getExpirationCookie = function getExpirationCookie(prefix) {
  return getCookie(prefixedName(prefix, 'republic-auth-expiration'));
};
export function isTokenExpired(expiresAt) {
  var expiresAtTime = new Date(+expiresAt).getTime();
  return expiresAtTime < Date.now();
}
export function isTokenExpiringSoon(expiresAt) {
  var expiresAtTime = new Date(+expiresAt).getTime();
  return expiresAtTime - Date.now() < ONE_MINUTES_MILLISECONDS;
}
export var isMobileWebView = function isMobileWebView() {
  return !!getCookie('mobile_app_version');
};