import { getWindow } from '@r-client/shared/util/core';
export var parseToken = function parseToken(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
export function signOutFromSearchParams(authModel, reporting) {
  var _getWindow, _authModel$viewer, _getWindow2;

  var currentLocation = (_getWindow = getWindow()) === null || _getWindow === void 0 ? void 0 : _getWindow.location.href;
  if (!currentLocation) return;
  var url = new URL(currentLocation);
  var signedOut = url.searchParams.get('signed_out');
  if (signedOut !== 'true') return;
  var logData = (_authModel$viewer = authModel.viewer) !== null && _authModel$viewer !== void 0 && _authModel$viewer.isAuthenticated ? {
    user: authModel.viewer.info.id
  } : {};
  reporting.info("User is being signed out due to search param.", logData);
  url.searchParams["delete"]('signed_out');
  (_getWindow2 = getWindow()) === null || _getWindow2 === void 0 ? void 0 : _getWindow2.history.replaceState(null, '', url);
  authModel.signOut({});
}