import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var GetUserDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query getUser {\n    user {\n      id\n      authId\n      requiresMultiFactorAuthentication\n      slug\n      email\n      confirmedAt\n      firstName\n      lastName\n      fullLegalName\n      avatarUrl(resize: { width: 110, height: 110, type: fill })\n      verified\n      founder\n      unreadMessagesCount\n      unreadNotificationsCount\n      lastIosAppSignInAt\n      signInCount\n      editableOfferings {\n        id\n        slug\n        companyName\n        logoUrl(resize: { width: 35, height: 35, type: fit })\n        logoUrl2x: logoUrl(resize: { width: 70, height: 70, type: fit })\n      }\n      secretsData {\n        authUri\n        secretKey\n      }\n      investorProfile {\n        id\n        xSelfAccredited\n        xRemainingInvestmentLimitCents\n        hasActiveRepublicSubscription\n        inxIntegrationAccount {\n          id\n          redirectUrl\n          state\n          createdAt\n          inxUserId\n        }\n      }\n      issuerApplications {\n        nodes {\n          id\n        }\n      }\n      issuerProfiles {\n        id\n        companyName\n        onboardingCompleteness\n        offerings {\n          id\n          logoUrl\n          hasPublished\n          indexInAllOfferings\n          regulation\n          slug\n        }\n      }\n      teaserPages(first: 1) {\n        nodes {\n          id\n          slug\n          companyName\n          backgroundImageUrl\n          logoImageUrl\n          summary\n          socialLinksUrls\n          tag {\n            slug\n          }\n        }\n      }\n      requestedSecondariesAccess\n      secondariesAccessGranted\n      accreditedAccess {\n        state\n      }\n      followsOfferings {\n        following\n        tags {\n          following\n        }\n      }\n      isAdmin\n      capabilities {\n        id\n        domain\n        level\n      }\n    }\n  }\n"])));
export var SignInDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation signIn($email: Email!, $password: String!, $source: AuthSourceEnum) {\n    signIn(input: { email: $email, password: $password, source: $source }) {\n      authFlags {\n        need2fa\n      }\n      signInCode\n      authToken {\n        accessToken\n        refreshToken\n        expiresAt\n      }\n      errors {\n        message\n        extensions {\n          code\n        }\n      }\n    }\n  }\n"])));
export var AuthenticateWithTwoFactorDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation authenticateWithTwoFactor($code: String!, $signInCode: String!) {\n    authenticateWithTwoFactor(input: { signInCode: $signInCode, code: $code }) {\n      authToken {\n        accessToken\n        refreshToken\n        expiresAt\n      }\n      errors {\n        message\n        extensions {\n          code\n        }\n      }\n    }\n  }\n"])));
export var SignOutDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation signOut($accessToken: String) {\n    UserAuth_signOutUser(accessToken: $accessToken) {\n      isLoggedBackInAsAdmin\n      errors {\n        message\n        extensions {\n          code\n        }\n      }\n    }\n  }\n"])));
export var RefreshTokenDocument = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation refreshToken($refreshToken: String!) {\n    Jwt_refreshToken(refreshToken: $refreshToken) {\n      token {\n        accessToken\n        refreshToken\n        idToken\n        expiresAt\n      }\n      errors {\n        message\n        extensions {\n          code\n        }\n      }\n    }\n  }\n"])));
export var UserEmailDocument = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query UserEmail($slug: String!) {\n    user(slug: $slug) {\n      id\n      email\n      unconfirmedEmail\n    }\n  }\n"])));