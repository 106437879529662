import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { theme as defaultTheme } from '@chakra-ui/theme';

var variantRepublicLine = function variantRepublicLine(props) {
  var _lineVariant, _lineVariant2, _lineVariant3;

  return _objectSpread(_objectSpread({}, lineVariant(props)), {}, {
    tablist: _objectSpread(_objectSpread({}, (_lineVariant = lineVariant(props)) === null || _lineVariant === void 0 ? void 0 : _lineVariant.tablist), {}, {
      borderBottomColor: 'grey.100',
      borderBottomWidth: '2px'
    }),
    tab: _objectSpread(_objectSpread({}, (_lineVariant2 = lineVariant(props)) === null || _lineVariant2 === void 0 ? void 0 : _lineVariant2.tab), {}, {
      padding: '15px 20px 8px',
      color: 'grey.500',
      fontWeight: '500',
      _selected: _objectSpread(_objectSpread({}, (_lineVariant3 = lineVariant(props)) === null || _lineVariant3 === void 0 ? void 0 : _lineVariant3.tab._selected), {}, {
        fontWeight: '700',
        color: 'blue.500',
        borderColor: 'blue.500'
      })
    }),
    tabpanel: {
      px: 0
    }
  });
};

export var Tabs = {
  variants: {
    'republic-line': variantRepublicLine
  }
};

function lineVariant(props) {
  var _defaultTheme$compone;

  return (_defaultTheme$compone = defaultTheme.components.Tabs.variants) === null || _defaultTheme$compone === void 0 ? void 0 : _defaultTheme$compone.line(props);
}