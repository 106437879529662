import { typeCast } from './utils';
export function getWindow() {
  if (false) {
    return undefined;
  }

  return typeCast(window);
}
export function getDocument() {
  if (typeof document === 'undefined') {
    return undefined;
  }

  return document;
}
export function getLocalStorage() {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }

  return localStorage;
}
export function getSessionStorage() {
  if (typeof sessionStorage === 'undefined') {
    return undefined;
  }

  return sessionStorage;
}
export function getFullStory() {
  if (typeof FS === 'undefined') {
    return undefined;
  }

  return FS;
}
export function runMonolithIFrameCallback(callbackName) {
  var _window$monolithCallb, _window$monolithCallb2;

  var window = getWindow();
  window === null || window === void 0 ? void 0 : (_window$monolithCallb = window.monolithCallbacks) === null || _window$monolithCallb === void 0 ? void 0 : (_window$monolithCallb2 = _window$monolithCallb[callbackName]) === null || _window$monolithCallb2 === void 0 ? void 0 : _window$monolithCallb2.call(_window$monolithCallb);
}