import { NormalizedCacheObject } from '@apollo/client';

import {
  createErrorLink,
  createGqlClient as createGqlClientInternal,
  TReporting,
  useGqlManager as useGqlManagerInternal,
} from '@r-client/shared/data/client';

import { apiClientConfig } from './application.config';
import { createServerErrorReporting } from './error-reporting';

export const createGqlClient = (initialState?: NormalizedCacheObject) =>
  createGqlClientInternal({
    initialState,
    config: apiClientConfig,
  });

export const useGqlManager = (pageProps: unknown, reporting?: TReporting) =>
  useGqlManagerInternal(pageProps, apiClientConfig, reporting);

export const createServerGqlClient = (initialState?: NormalizedCacheObject) => {
  const client = createGqlClientInternal({
    initialState,
    config: {
      ...apiClientConfig,
      authPersistence: { type: 'none' },
    },
  });
  client.setLink(
    createErrorLink(createServerErrorReporting()).concat(client.link)
  );
  return client;
};
