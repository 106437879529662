export var TSIZES = ['lg', 'md', 'sm', 'xs'];
export function extract(pr, is) {
  return function (t) {
    if (t === undefined) {
      return [];
    }

    if (is(t)) {
      var _pr;

      return (_pr = pr(t, 'lg')) !== null && _pr !== void 0 ? _pr : [];
    } else {
      return TSIZES.reduce(function (memo, size) {
        var val = t[size];

        if (val !== undefined) {
          var res = pr(val, size);

          if (res !== undefined) {
            memo.push(res);
          }
        }

        return memo;
      }, []);
    }
  };
}

function isString(v) {
  return typeof v === 'string';
}

function isBool(v) {
  return typeof v === 'boolean';
}

export function getClassName(name, size) {
  var t = size === 'lg' ? "".concat(name) : "".concat(name, "-").concat(size);
  return t;
}
export function createBooleanGetter(styleGetter) {
  return extract(styleGetter, isBool);
}
export function createStringGetter(styleGetter) {
  return extract(styleGetter, isString);
}