import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

/* eslint-disable @nx/enforce-module-boundaries */
import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

var _createMultiStyleConf = createMultiStyleConfigHelpers(popoverAnatomy.keys),
    defineMultiStyleConfig = _createMultiStyleConf.defineMultiStyleConfig;

var $arrowBg = cssVar('popper-arrow-bg');
export var Popover = defineMultiStyleConfig({
  variants: {
    republic: {
      content: {
        background: 'grey.900',
        color: 'white',
        px: '3',
        py: '2.5',
        borderRadius: 'md',
        border: 'none'
      },
      arrow: _defineProperty({}, $arrowBg.variable, '#1A1A1A')
    }
  }
});