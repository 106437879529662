// https://chakra-ui.com/docs/styled-system/theming/customize-theme
// eslint-disable-next-line @nx/enforce-module-boundaries
import { extendTheme } from '@chakra-ui/react'; // COMPONENTS:

import { Alert } from './components/alert';
import { Badge } from './components/badge';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { Form } from './components/form';
import { FormLabel } from './components/form-label';
import { Heading } from './components/heading';
import { Input } from './components/input';
import { Link } from './components/link';
import { Modal } from './components/modal';
import { Popover } from './components/popover';
import { Radio } from './components/radio';
import { Select } from './components/select';
import { Skeleton } from './components/skeleton';
import { Switch } from './components/switch';
import { Tabs } from './components/tabs';
import { Text } from './components/text';
import { Textarea } from './components/textarea';
import { Tooltip } from './components/tooltip'; // FOUNDATIONS:

import { radii } from './foundations/border-radius';
import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { layerStyles } from './foundations/layer-styles';
import { semanticTokens } from './foundations/semantic-tokens';
import { fonts, fontSizes, letterSpacings, lineHeights, textStyles } from './foundations/typography';
export * from './utilities';
export var theme = extendTheme({
  breakpoints: breakpoints,
  colors: colors,
  fonts: fonts,
  fontSizes: fontSizes,
  letterSpacings: letterSpacings,
  lineHeights: lineHeights,
  layerStyles: layerStyles,
  radii: radii,
  semanticTokens: semanticTokens,
  textStyles: textStyles,
  components: {
    Alert: Alert,
    Badge: Badge,
    Button: Button,
    Checkbox: Checkbox,
    Form: Form,
    FormLabel: FormLabel,
    Heading: Heading,
    Input: Input,
    Modal: Modal,
    Radio: Radio,
    Select: Select,
    Skeleton: Skeleton,
    Switch: Switch,
    Tabs: Tabs,
    Text: Text,
    Tooltip: Tooltip,
    Textarea: Textarea,
    Link: Link,
    Popover: Popover
  }
});
export var embeddedTheme = extendTheme(theme, {
  styles: {
    global: {
      'html, body': {
        height: 'auto',
        overflow: 'hidden'
      }
    }
  }
});