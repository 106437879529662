import { useEffect } from 'react';
import { useReporting } from '../reporter-context';

/**
 * This component will watch NextRouter updates for you app.
 * Just add it to layout and ensure it renders
 */
export var NavigationContextHandler = function NavigationContextHandler(_ref) {
  var useRouter = _ref.useRouter;
  var router = useRouter();
  var reporting = useReporting();
  useEffect(function () {
    reporting.configure({
      payload: {
        context: router.pathname
      }
    });
  }, [reporting, router, router.pathname]);
  return null;
};