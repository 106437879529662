import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// /* eslint-disable @nx/enforce-module-boundaries */
import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { theme as defaultTheme } from '@chakra-ui/theme';

var _createMultiStyleConf = createMultiStyleConfigHelpers(inputAnatomy.keys),
    defineMultiStyleConfig = _createMultiStyleConf.defineMultiStyleConfig;

var republicInputBaseStyles = {
  border: '1px solid',
  borderColor: 'brand.grey.e0',
  bg: 'white',
  _hover: {
    borderColor: 'brand.grey.c4'
  },
  _focusVisible: {
    borderColor: 'brand.primary',
    boxShadow: '0 0 2px 2px rgb(85 85 85 / 5%)'
  },
  _placeholder: {
    color: 'brand.grey.ca'
  }
};
export var Input = defineMultiStyleConfig({
  variants: {
    "default": {
      field: _objectSpread({}, republicInputBaseStyles)
    },
    'republic-outline-large': function republicOutlineLarge(props) {
      var _defaultTheme$compone, _defaultTheme$compone2, _defaultTheme$compone3;

      return _objectSpread(_objectSpread({}, (_defaultTheme$compone = defaultTheme.components.Input.variants) === null || _defaultTheme$compone === void 0 ? void 0 : _defaultTheme$compone.outline(props)), {}, {
        field: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (_defaultTheme$compone2 = defaultTheme.components.Input.variants) === null || _defaultTheme$compone2 === void 0 ? void 0 : _defaultTheme$compone2.outline(props).field), (_defaultTheme$compone3 = defaultTheme.components.Input.sizes) === null || _defaultTheme$compone3 === void 0 ? void 0 : _defaultTheme$compone3.lg.field), republicInputBaseStyles), {}, {
          fontSize: '26',
          border: '2px solid',
          _hover: _objectSpread(_objectSpread({}, republicInputBaseStyles['_hover']), {}, {
            boxShadow: '0 4px 20px rgb(0 0 0 / 10%)'
          }),
          _focusVisible: _objectSpread(_objectSpread({}, republicInputBaseStyles['_focusVisible']), {}, {
            border: '2px solid',
            boxShadow: '0 4px 20px rgb(0 0 0 / 10%)'
          }),
          py: '3',
          fontWeight: 'light',
          height: '14'
        })
      });
    },
    'republic-outline': function republicOutline(props) {
      var _defaultTheme$compone4, _defaultTheme$compone5, _defaultTheme$compone6;

      return _objectSpread(_objectSpread({}, (_defaultTheme$compone4 = defaultTheme.components.Input.variants) === null || _defaultTheme$compone4 === void 0 ? void 0 : _defaultTheme$compone4.outline(props)), {}, {
        field: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (_defaultTheme$compone5 = defaultTheme.components.Input.variants) === null || _defaultTheme$compone5 === void 0 ? void 0 : _defaultTheme$compone5.outline(props).field), (_defaultTheme$compone6 = defaultTheme.components.Input.sizes) === null || _defaultTheme$compone6 === void 0 ? void 0 : _defaultTheme$compone6.lg.field), republicInputBaseStyles), {}, {
          height: '10'
        })
      });
    },
    'republic-outline-small': function republicOutlineSmall(props) {
      var _defaultTheme$compone7, _defaultTheme$compone8, _defaultTheme$compone9;

      return _objectSpread(_objectSpread({}, (_defaultTheme$compone7 = defaultTheme.components.Input.variants) === null || _defaultTheme$compone7 === void 0 ? void 0 : _defaultTheme$compone7.outline(props)), {}, {
        field: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (_defaultTheme$compone8 = defaultTheme.components.Input.variants) === null || _defaultTheme$compone8 === void 0 ? void 0 : _defaultTheme$compone8.outline(props).field), (_defaultTheme$compone9 = defaultTheme.components.Input.sizes) === null || _defaultTheme$compone9 === void 0 ? void 0 : _defaultTheme$compone9.md.field), republicInputBaseStyles), {}, {
          height: '9'
        })
      });
    },
    'republic-outline-lite': function republicOutlineLite(props) {
      var _defaultTheme$compone10, _defaultTheme$compone11, _defaultTheme$compone12;

      return _objectSpread(_objectSpread({}, (_defaultTheme$compone10 = defaultTheme.components.Input.variants) === null || _defaultTheme$compone10 === void 0 ? void 0 : _defaultTheme$compone10.outline(props)), {}, {
        field: _objectSpread(_objectSpread(_objectSpread({}, (_defaultTheme$compone11 = defaultTheme.components.Input.variants) === null || _defaultTheme$compone11 === void 0 ? void 0 : _defaultTheme$compone11.outline(props).field), (_defaultTheme$compone12 = defaultTheme.components.Input.sizes) === null || _defaultTheme$compone12 === void 0 ? void 0 : _defaultTheme$compone12.md.field), {}, {
          py: '3',
          px: '10px',
          height: '42px',
          borderRadius: 4,
          borderColor: 'grey.100',
          bg: 'white',
          color: 'grey.900',
          _hover: {
            borderColor: 'grey.200'
          },
          _active: {
            borderColor: 'blue.800'
          },
          _focusVisible: {
            boxShadow: 'none',
            borderColor: 'blue.500'
          },
          _focus: {
            borderColor: 'blue.500'
          },
          _invalid: {
            borderColor: 'red.500'
          },
          _valid: {
            borderColor: 'green.500'
          },
          _disabled: {
            borderColor: 'grey.200',
            bgColor: 'grey.50',
            cursor: 'not-allowed',
            _hover: {
              borderColor: 'grey.200'
            }
          }
        })
      });
    }
  },
  defaultProps: {
    variant: 'default'
  }
});