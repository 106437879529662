// DLS Storybook:
// https://dev--6405b205e9645eaf8924aa0b.chromatic.com/?path=/docs/foundations-borderradius--docs&globals=chakraTheme:republic
export var radii = {
  none: '0',
  sm: '0.125rem',
  base: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  xl: '0.75rem',
  '2xl': '1rem',
  '3xl': '1.25rem',
  '4xl': '1.5rem',
  full: '9999px'
};