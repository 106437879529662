export var Textarea = {
  variants: {
    'republic-lite': {
      border: '1px solid',
      borderColor: 'brand.grey.e0',
      borderRadius: 'base',
      _placeholder: {
        color: 'brand.grey.cc',
        fontSize: 'md',
        lineHeight: 'md',
        fontWeight: 'normal',
        letterSpacing: 'less-tight'
      },
      color: 'brand.grey.22',
      fontSize: 'md',
      lineHeight: 'md',
      fontWeight: 'normal',
      letterSpacing: 'less-tight',
      position: 'relative'
    }
  }
};