export var FormLabel = {
  variants: {
    republic: {
      fontSize: 'md',
      fontWeight: 'semibold',
      lineHeight: 'md',
      letterSpacing: 'less-tight',
      color: 'grey.800'
    }
  }
};