import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

var _createMultiStyleConf = createMultiStyleConfigHelpers(checkboxAnatomy.keys),
    defineMultiStyleConfig = _createMultiStyleConf.defineMultiStyleConfig;

export var Checkbox = defineMultiStyleConfig({
  baseStyle: {
    label: {
      ml: 2
    }
  },
  variants: {
    'republic-primary': {
      control: {
        borderColor: 'black',
        _checked: {
          borderColor: 'blue.500',
          bg: 'blue.500'
        }
      }
    },
    republic: {
      container: {
        display: 'flex',
        alignItems: 'flex-start',
        _hover: {
          '> span': {
            borderColor: 'brand.blue.34'
          },
          _disabled: {
            '> span': {
              borderColor: 'grey.200',
              bgColor: 'grey.50'
            }
          },
          _checked: {
            '> span:first-of-type': {
              borderColor: 'brand.blue.34',
              bgColor: 'brand.blue.34'
            },
            _active: {
              '> span:first-of-type': {
                borderColor: 'brand.blue.c6',
                bgColor: 'brand.blue.c6'
              }
            },
            _disabled: {
              '> span:first-of-type': {
                borderColor: 'brand.blue.8b',
                bgColor: 'brand.blue.8b'
              }
            }
          }
        }
      },
      control: {
        width: '18px',
        height: '18px',
        mt: '3px',
        bg: 'white',
        border: '1px solid',
        borderColor: 'grey.200',
        borderRadius: 'sm',
        _hover: {
          borderColor: 'brand.blue.34',
          _checked: {
            bgColor: 'brand.blue.34',
            borderColor: 'brand.blue.34',
            _active: {
              borderColor: 'brand.blue.c6',
              bgColor: 'brand.blue.c6'
            }
          },
          _active: {
            borderColor: 'brand.blue.c6'
          }
        },
        _checked: {
          bgColor: 'blue.500',
          borderColor: 'blue.500',
          _hover: {
            bgColor: 'brand.blue.34',
            borderColor: 'brand.blue.34'
          },
          _active: {
            bgColor: 'blue.800',
            borderColor: 'blue.800'
          },
          _disabled: {
            bgColor: 'brand.blue.8b',
            borderColor: 'brand.blue.8b'
          }
        },
        _disabled: {
          bgColor: 'grey.50',
          borderColor: 'grey.200',
          opacity: 1
        }
      },
      label: {
        fontSize: 'md',
        lineHeight: 'md',
        fontWeight: '400',
        letterSpacing: 'less-tight',
        color: 'black',
        _disabled: {
          color: 'black',
          opacity: 1
        }
      }
    },
    'republic-outline': {
      container: {
        alignItems: 'flex-start'
      },
      control: {
        width: '20px',
        height: '20px',
        borderColor: 'grey.200',
        borderWidth: '1px',
        bg: 'white',
        mt: 0.5,
        _checked: {
          borderColor: 'blue.500',
          bg: 'blue.500'
        }
      }
    },
    'republic-offering': {
      control: {
        width: '18px',
        height: '18px',
        background: 'white',
        borderRadius: 'sm',
        opacity: 0.9,
        border: '1px solid',
        borderColor: 'grey.200',
        alignSelf: 'flex-start',
        _checked: {
          border: 'none',
          bgColor: 'blue.500',
          _disabled: {
            opacity: 1,
            borderColor: 'none',
            bgColor: 'blue.300',
            color: 'white'
          }
        }
      },
      label: {
        _checked: {
          _disabled: {
            opacity: 1
          }
        }
      }
    }
  }
});