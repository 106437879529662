var republicFont = "'Inter var', 'Inter', 'Helvetica Neue', Arial, sans-serif";
var republicCode = "'SFMono-Regular', Roboto Mono, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace";
export var fonts = {
  heading: republicFont,
  body: republicFont,
  code: republicCode
};
export var letterSpacings = {
  tightest: '-0.04em',
  tighter: '-0.03em',
  tight: '-0.02em',
  'tight-dls': '-0.0225em',
  'less-tight': '-0.01em',
  normal: '0',
  wide: '0.02em',
  wider: '0.03em',
  widest: '0.04em'
};
export var lineHeights = {
  normal: 'normal',
  '6xl': '4rem',
  '5xl': '3.125rem',
  '4xl': '3rem',
  '3xl': '2.5rem',
  '2xl': '2.125rem',
  xl: '1.875rem',
  lg: '1.625rem',
  md: '1.5rem',
  sm: '1rem',
  xs: '0.75rem',
  none: 1,
  tinier: 1.125,
  tiny: 1.2,
  shorter: 1.25,
  "short": 1.375,
  base: 1.5,
  tall: 1.625,
  taller: 1.725
};
export var fontSizes = {
  '8': '8px',
  '10': '10px',
  '11': '11px',
  '12': '12px',
  '13': '13px',
  '14': '14px',
  '16': '16px',
  '25': '25px',
  '30': '30px',
  '40': '40px',
  xs: '0.625rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.5rem',
  '2xl': '1.75rem',
  '3xl': '2.25rem',
  '4xl': '3rem',
  '5xl': '3.25rem',
  '6xl': '4.25rem'
};
export var textStyles = {
  lead: {
    fontSize: 'xl',
    lineHeight: 'xl',
    letterSpacing: 'tight'
  },
  'lg-dls': {
    // Use this instead of 'lg' - this is correct as per DLS. current 'lg' will be removed after migration
    fontSize: 'lg',
    lineHeight: 'md',
    letterSpacing: 'tight-dls'
  },
  lg: {
    /** @deprecated Use 'lg-dls' instead for new layouts **/
    fontSize: 'lg',
    lineHeight: 'lg',
    letterSpacing: 'tight'
  },
  md: {
    fontSize: 'md',
    lineHeight: 'md',
    letterSpacing: 'less-tight'
  },
  'md-bold': {
    fontSize: 'md',
    fontWeight: '700',
    lineHeight: 'md',
    letterSpacing: 'less-tight'
  },
  sm: {
    fontSize: 'sm',
    lineHeight: 'sm',
    letterSpacing: 'less-tight'
  },
  xs: {
    fontSize: 'xs',
    lineHeight: 'sm',
    letterSpacing: 'normal'
  }
};