export var layerStyles = {
  form: {
    bgColor: 'brand.grey.f7',
    color: 'brand.grey.22'
  },
  dashed: {
    borderRadius: 3,
    border: '1px dashed',
    borderColor: 'brand.primary-disabled',
    opacity: '0.5'
  }
};