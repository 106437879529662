/* eslint-disable no-process-env */

import {
  IGqlClientConfig,
  LOCAL_GRAPHQL_ENDPOINT,
  LOCAL_SEEDRS_API_ENDPOINT,
} from '@r-client/shared/data/client';
import { IPublicConfig, TAuthPersistence } from '@r-client/shared/util/core';

export interface IServerConfig {
  rollbarServerToken: string | undefined;
  skipBuildStaticGeneration: boolean;
}

export const getServerConfig = (): IServerConfig => {
  return {
    rollbarServerToken: process.env.ROLLBAR_SERVER_TOKEN,
    skipBuildStaticGeneration:
      process.env.SKIP_BUILD_STATIC_GENERATION === 'true',
  };
};

const googleMapsEmbedUrl =
  process.env.NEXT_PUBLIC_GOOGLE_MAP_BASE_URL &&
  process.env.NEXT_PUBLIC_GOOGLE_MAP_EMBED_KEY
    ? process.env.NEXT_PUBLIC_GOOGLE_MAP_BASE_URL.replace(
        '<<embed_key>>',
        process.env.NEXT_PUBLIC_GOOGLE_MAP_EMBED_KEY
      )
    : undefined;

export const getPublicConfig = (): IPublicConfig => {
  if (process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT === undefined) {
    throw new Error(
      'process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT not found. Please provide NEXT_PUBLIC_GRAPHQL_ENDPOINT in env.'
    );
  }

  if (process.env.NEXT_PUBLIC_URL_ORIGIN === undefined) {
    throw new Error(
      'process.env.NEXT_PUBLIC_URL_ORIGIN not found. Please provide NEXT_PUBLIC_URL_ORIGIN in env.'
    );
  }

  if (process.env.NEXT_PUBLIC_SEEDRS_API_BASE_URL === undefined) {
    throw new Error(
      'process.env.NEXT_PUBLIC_SEEDRS_API_BASE_URL not found. Please provide NEXT_PUBLIC_SEEDRS_API_BASE_URL in env.'
    );
  }

  // TODO: MK Uncomment this when we have bifrost as default
  // if (process.env.NEXT_PUBLIC_GLOBAL_AUTH_BASE_URL === undefined) {
  //   throw new Error(
  //     'process.env.NEXT_PUBLIC_GLOBAL_AUTH_BASE_URL not found. Please provide NEXT_PUBLIC_GLOBAL_AUTH_BASE_URL in env.'
  //   );
  // }

  return {
    graphqlEndpointUrl:
      process.env.NODE_ENV === 'development' && typeof window === 'object'
        ? LOCAL_GRAPHQL_ENDPOINT
        : process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
    seedrsApiBaseUrl:
      process.env.NODE_ENV === 'development' && typeof window === 'object'
        ? LOCAL_SEEDRS_API_ENDPOINT
        : process.env.NEXT_PUBLIC_SEEDRS_API_BASE_URL,
    globalAuthBaseUrl: process.env.NEXT_PUBLIC_GLOBAL_AUTH_BASE_URL,
    republicEuropeBaseUrl: process.env.NEXT_PUBLIC_REPUBLIC_EUROPE_BASE_URL,
    applicationEnvironment:
      (process.env.NEXT_PUBLIC_APP_ENV as 'staging') || 'production',
    applicationVersion: process.env.NEXT_PUBLIC_APP_VERSION || 'no-version-set',
    rollbarClientToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    googleRecaptchaSiteKey: process.env.NEXT_PUBLIC_GRECAPTCHA_V3_SITE_KEY,
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    segmentClientWriteKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    googleMapsEmbedUrl,
    zendeskKey: process.env.NEXT_PUBLIC_ZENDESK_KEY,
    applicationURLOrigin: process.env.NEXT_PUBLIC_URL_ORIGIN,
    authPersistence: {
      type: 'cookie',
      cookiePrefix: process.env.NEXT_PUBLIC_AUTH_COOKIE_PREFIX,
    } as TAuthPersistence,
    brazeApiKey: process.env.NEXT_PUBLIC_BRAZE_API_KEY,
    brazeBaseUrl: process.env.NEXT_PUBLIC_BRAZE_BASE_URL,
    froalaKey: process.env.NEXT_PUBLIC_FROALA_KEY,
    etherScanUrl: process.env.NEXT_PUBLIC_ETHER_SCAN,
    algoExplorerUrl: process.env.NEXT_PUBLIC_ALGO_EXPLORER,
    avaxNetworkUrl: process.env.NEXT_PUBLIC_AVAX_NETWORK,
    wallabyApiKey: process.env.NEXT_PUBLIC_WALLABY_API_KEY,
    wallabyBaseUrl: process.env.NEXT_PUBLIC_WALLABY_BASE_URL,
    comingSoonTokensList: process.env.NEXT_PUBLIC_COMING_SOON_TOKENS_LIST,
  };
};

export const apiClientConfig: IGqlClientConfig = {
  apiAuthHeader: process.env.NEXT_PUBLIC_GRAPHQL_AUTH,
  apiUri: getPublicConfig().graphqlEndpointUrl,
  clientName: 'republic-web',
  clientVersion: process.env.NEXT_PUBLIC_APP_VERSION || 'no-version',
  authPersistence: getPublicConfig().authPersistence,
};
